import React, {useEffect} from 'react';
import {useState} from "react";
import {Box, Stack, Button} from "@mui/material";
import ProductCard from "../components/ProductCard";
import {useParams} from "react-router-dom";

const SeeAllPage = (props) => {
    const params = useParams();
    let categ = params.categ;

    const [categItems, setCategItems] = useState([])
    const [rows, setRows] = useState([])

    async function fetchItems(){
        console.log(categ);
        const response = await fetch("https://asarm00.pythonanywhere.com/items/"+categ+"/40");
        const data = await response.json();

        // console.log(data);
        const items = []

        for(var i=0; i<40; i++){
            var prod = data["items"][i];

            items.push(
                        <ProductCard
                            prodId={prod.article_id}
                            title={prod.prod_name}
                            desc={prod.detail_desc}
                            image_url={prod.URL}
                        />)
            }

            setCategItems(items);

            const temp_rows = [];
            for (let i = 0; i < categItems.length; i += 6) {
                temp_rows.push(categItems.slice(i, i + 6));
            }

            setRows(temp_rows);
    }

    useEffect(() => {
        console.log(props.categ);
        fetchItems();
    })

    return(
            <Box sx={{}} flex={4}>
                <Stack direction='row'>
                    <h2>{categ.toUpperCase()}</h2>
                    <Button size="small" sx={{ marginLeft: "1%" }}>See All</Button>
                </Stack>


                {
                rows.map((row, i) => (
                        <Stack direction='row' mt={5} key={i}>
                            {row.map((item, j) => (
                                    <Box key={j}>{item}</Box>
                                    ))}
                        </Stack>
                ))
                }

            </Box>
    );
}

export default SeeAllPage;
import {React, useEffect, useState} from 'react';
import {AppBar, styled, Toolbar, Box, Stack, Button, Typography} from "@mui/material";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const SeacrhCard = (props) => {
    console.log( "../data/prods4App/"+props.prodId+".jpg");
    return (
            <Card sx={{ maxWidth: 250, marginRight: "5%" }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="200"
                        image= {props.imageUrl ? props.imageUrl : "https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-question-mark-icon-png-image_515448.jpg"}
                        alt="Prod Image"
                        sx={{ objectFit: "contain" }}
                    />

                    <CardContent>
                        <Typography gutterBottom variant="p" component="div">
                            {props.title ? props.title : ""}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {props.title ? String(props.desc).slice(0, 25)+" ..." : ""}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            )
}

export default SeacrhCard;
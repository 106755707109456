import React from 'react';
import {useState} from "react";

import {Box, Stack, Button, IconButton, TextField} from "@mui/material";
import SearchCard from "./SearchCard";
import Drawer from '@mui/material/Drawer';
import ProductCard from "./ProductCard";


const Sidebar = () => {
    const [prodUrl, setProdUrl] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [recomList, setRecomList] = useState([<ProductCard />,
                                                <ProductCard />,
                                                <ProductCard />,
                                                <ProductCard />])

    const onChangeLink = (event) => {
        setProdUrl(event.target.value)
    }

    const onSearch = (evet) => {
        const data = { img_url: prodUrl };

        fetch("http://127.0.0.1:8000/api/recoms/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
              console.log("Data:", data);
              const newList = data.map((prod) => (
                      <ProductCard prodId={prod.article_id} title={prod.title} desc={prod.desc}/>
                      ))
              setDrawerOpen(true);
              setRecomList(newList);
          })
          .catch((error) => console.error(error));
    }
    // https://cdn3.thelatestthing.co/cdn/98/3315228/3?md=1671602775

    return (
            <>
            <Box flex={1} sx={{}}>
                <Stack sx={{marginTop: "25%"}}>
                    <SearchCard imageUrl={prodUrl} />
                    <Stack direction='row'>
                        <IconButton color="red" aria-label="upload picture" component="label">
                            <TextField id="img_url" label="Image URL" variant="standard" onChange={onChangeLink}/>
                        </IconButton>
                        <Button variant="contained" color="warning" sx={{margin: '10px'}} size="small" onClick={onSearch}>Search</Button>
                    </Stack>
                </Stack>
            </Box>
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                >
                {<Box sx={{
                    height: 320,
                    backgroundColor: 'grey',
                }}>
                    <Stack direction="row" sx={{display:"flex", justifyContent:"center", marginTop: "2%"}}>
                        {recomList}
                    </Stack>
            </Box>
                }
            </Drawer>
            </>
            )
}

export default Sidebar;
import React, {useEffect} from 'react';
import {useState} from "react";
import {Box, Stack, Button} from "@mui/material";
import ProductCard from "../components/ProductCard";
import {
    BrowserRouter as Router,
    Route,
    Link, Routes
} from "react-router-dom";

const HomePage = () => {
    const [prods, setProds] = useState({});
    const [categories, setCategories] = useState([])

    async function fetchLatest(){
        const response = await fetch("https://asarm00.pythonanywhere.com/headlines");
        const data = await response.json();

        console.log(data);
        setProds(data);
        setCategories(Object.keys(data))
    }


    useEffect(() => {
        fetchLatest();
    }, [])

    return (
            <Box sx={{}} flex={4}>
                {categories.map((category) => (
                        <Stack direction='column' mt={5}>
                            <Link to={`/items/${category}`}>
                                <Stack direction='row' sx={{ color: "blue", textDecoration: "none"}}>
                                    <h2 sx={{ color: "blue", textDecoration: "none" }}>{category.toUpperCase()}</h2>
                                    <Button size="small" sx={{ marginLeft: "1%" }}>See All</Button>
                                </Stack>
                            </Link>
                            <Stack direction='row'>
                                <ProductCard
                                    prodId={prods[category]["items"][0]["article_id"]}
                                    title={prods[category]["items"][0]["prod_name"]}
                                    desc={prods[category]["items"][0]["detail_desc"]}
                                    image_url={prods[category]["items"][0]["URL"]}
                                />
                                <ProductCard
                                    prodId={prods[category]["items"][1]["article_id"]}
                                    title={prods[category]["items"][1]["prod_name"]}
                                    desc={prods[category]["items"][1]["detail_desc"]}
                                    image_url={prods[category]["items"][1]["URL"]}
                                />
                                <ProductCard
                                    prodId={prods[category]["items"][2]["article_id"]}
                                    title={prods[category]["items"][2]["prod_name"]}
                                    desc={prods[category]["items"][2]["detail_desc"]}
                                    image_url={prods[category]["items"][2]["URL"]}
                                />
                                <ProductCard
                                    prodId={prods[category]["items"][3]["article_id"]}
                                    title={prods[category]["items"][3]["prod_name"]}
                                    desc={prods[category]["items"][3]["detail_desc"]}
                                    image_url={prods[category]["items"][3]["URL"]}
                                />
                            </Stack>
                        </Stack>
                        ))}
            </Box>
    );
}

export default HomePage;
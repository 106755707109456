import {Box, Stack} from "@mui/material";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import HomePage from "./pages/HomePage";
import SeeAllPage from "./pages/SeeAllPage";

import {
  BrowserRouter as Router,
  Route,
  Link, Routes
} from "react-router-dom";


function App() {

  return (
          <Router>
            <div className="App">
              <Box sx={{backgroundColor:"#edf2f2"}}>
                <Navbar />
                <Stack spacing={2} direction='row' justifyContent='space-between'>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/items/:categ" element={<SeeAllPage />} />
                  </Routes>
                  <Sidebar />
                </Stack>
              </Box>
            </div>
          </Router>
  );
}

export default App;

import React from 'react';
import {AppBar, styled, Toolbar, Box} from "@mui/material";

const Icons = styled(Box)(({ theme }) => ({
    display: "flex", gap: "30px", alignItems: 'center'
}));

const Navbar = () => {
    return(
            <AppBar position='static' sx={{backgroundColor: "#fbeeff"}}>
                <Toolbar sx={{
                    display: 'flex', justifyContent: 'space-between'
                }}>
                    <img src="https://storage.googleapis.com/pai-images/a5cd4485350e40aab1cb58d2b4eb0679.jpeg" alt="logo" height="65px"/>
                </Toolbar>
            </AppBar>
            )
}

export default Navbar;
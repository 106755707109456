import {React, useState} from 'react';
import {Box, Stack, Typography} from "@mui/material";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Drawer from '@mui/material/Drawer';

const ProductCard = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [recoms, setRecoms] = useState([])
    const [recomList, setRecomList] = useState([<ProductCard />,
                                                <ProductCard />,
                                                <ProductCard />])

    async function fetchRecoms(){
        console.log("Recoms called...", props.prodId)
        const response = await fetch("https://asarm00.pythonanywhere.com/similars/"+props.prodId+"/0");
        const data = await response.json();

        // console.log(data);

        const newList = []

        for(var i=0; i < 3; i++){
            var prod = data[i];
            // console.log(data[i]);
            newList.push(
                    <ProductCard
                        prodId={prod.article_id}
                        title={prod.prod_name}
                        desc={prod.detail_desc}
                        image_url={prod.URL}
                    />
            )
        }

        setRecomList(newList);

    }

    const btnClick = () =>{
        fetchRecoms();
        setDrawerOpen(true);
    }

    return (
            <>
            <Card sx={{ maxWidth: 250, marginRight: "5%" }} onClick={btnClick}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="200"
                        image= {props.image_url ? props.image_url : "https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-question-mark-icon-png-image_515448.jpg"}
                        alt="product image"
                        sx={{ objectFit: "contain" }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="p" component="div">
                            {props.title ? props.title : ""}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {props.desc ? String(props.desc).slice(0, 25)+"..." : ""}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                >
                {<Box sx={{
                    height: 320,
                    backgroundColor: 'grey',
                }}>
                    <Stack direction="row" sx={{display:"flex", justifyContent:"center", marginTop: "2%"}}>
                        {recomList}
                    </Stack>
            </Box>
                }
            </Drawer>
            </>
            )
}

export default ProductCard;
